import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import companyService from '../../services/companies';

import './FeedbackForm.css'; // Assume you have some basic CSS here
import useSendFeedback from './useSendFeedback';

// Reusable Button Component
const Button = ({ text, onClick, disabled }) => (
	<button className="btn" onClick={onClick} disabled={disabled}>
		{text}
	</button>
);

// Reusable TextArea Component
const TextArea = ({ value, onChange, placeholder }) => <textarea className="textarea" value={value} onChange={onChange} placeholder={placeholder} />;

// Reusable Link Component
const Link = ({ href, children }) => (
	<a className="link" href={href}>
		{children}
	</a>
);

const useHauler = (slug) => {
	const [hauler, setHauler] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (loading) return;

		setLoading(true);

		try {
			new companyService()
				.getCompanyByUrl(slug)
				.then((h) => {
					if (h) {
						const theme = JSON.parse(h.theme);
						const payfrequency = JSON.parse(h.payfrequency);
						setHauler({ ...h, theme, payfrequency });
					} else {
						setError('Unable to load hauler');
					}
				})
				.catch((er) => setError('Unable to load hauler'))
				.finally((e) => setLoading(false));
		} catch (e) {}
	}, [slug]);

	return {
		hauler,
		loading,
		error,
	};
};

// /'RECOMMEND', 'SOCIAL_REVIEW', 'FEEDBACK'
const STATES = {
	STAGE1: 'RECOMMEND',
	STAGE2: 'SOCIAL_REVIEW',
	STAGE3: 'FEEDBACK',
};

const useReviewState = () => {
	const [state, setState] = useState(STATES.STAGE1);

	return { state, setState };
};

const SocialReviewScreen = ({ facebookReviewsLink, googleReviewsLink }) => {
	return (
		<>
			<p className="subtitle">Select a site to leave a review.</p>
			<div className="buttons">
				{!!facebookReviewsLink && (
					<a href={facebookReviewsLink} className="button facebook">
						<img src="/assets/images/logo/facebook-button-text.png" alt="Facebook" />
					</a>
				)}
				{!!googleReviewsLink && (
					<a href={googleReviewsLink} className="button google">
						<img src="/assets/images/logo/google-button-text.png" alt="Google" />
					</a>
				)}
			</div>
		</>
	);
};

const RecommendScreen = ({ name, setState }) => {
	const handleYesClick = () => setState(STATES.STAGE2);
	const handleNoClick = () => setState(STATES.STAGE3);

	return (
		<>
			<p className="subtitle">Would you recommend {name}?</p>
			<div className="buttons">
				<button className="button yes" onClick={handleYesClick}>
					Yes
				</button>
				<button className="button no" onClick={handleNoClick}>
					No
				</button>
			</div>
		</>
	);
};

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const FeedbackScreen = React.memo(() => {
	const { hauler: slug } = useParams();
	const searchParams = useQuery();
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');
	const { sendFeedback, success } = useSendFeedback();

	const name = searchParams.get('name');
	const account = searchParams.get('account');

	const handleMessageChange = (e) => {
		setMessage(e.target.value);
	};

	const handleSubmitFeedback = (e) => {
		e.preventDefault();

		setError('');

		if (message.trim() === '') {
			alert('Please enter a message');
			return;
		}

		// Handle form submission logic here
		sendFeedback(slug, name, account, message);
	};

	if (success) {
		return (
			<>
				<p className="subtitle">Your feedback means a lot to us!</p>
				<p className="description">Please close this window at your convenience.</p>
			</>
		);
	}

	return (
		<>
			{!!error && (
				<div className="error">
					<p>{error}</p>
				</div>
			)}
			<p className="subtitle">How can we improve?</p>
			<p className="description">Send us direct feedback and we’ll be in touch to make things right.</p>
			<form onSubmit={handleSubmitFeedback}>
				<textarea onChange={handleMessageChange} className="textarea" placeholder="Type your message"></textarea>
				<button type="submit" className="button submit">
					Submit Feedback
				</button>
			</form>
			<p className="footer-text">If you'd prefer, you can contact us here.</p>
		</>
	);
});

// Main ReviewsPage Component
const ReviewsPage = () => {
	const { hauler: slug } = useParams();

	const { loading, hauler, error } = useHauler(slug);

	const { state, setState } = useReviewState();

	useEffect(() => {
		if (!loading && error === 'Unable to load hauler') {
			console.error('Error loading hauler');
			// push('/'); // Redirect to home page or error page
		}
	}, [loading, error]);

	useEffect(() => {
		document.documentElement.style.setProperty('--background-color', hauler?.theme?.primaryColor || '#333333');
		document.documentElement.style.setProperty('--text-color', hauler?.theme?.secondaryColor || '#333333');
	}, [hauler?.theme?.primaryColor]);

	const title = hauler?.name || 'TRASH JOES';
	const logoSrc = hauler?.logo;

	if (!hauler && !error) return null;

	return (
		<div className="feedback-page">
			<div className="review-box">
				{logoSrc ? <img src={logoSrc} alt={title} className="logo" /> : <h1 className="title">{title}</h1>}
				{state === STATES.STAGE1 && <RecommendScreen name={hauler?.name} setState={setState} />}
				{state === STATES.STAGE2 && <SocialReviewScreen facebookReviewsLink={hauler.facebookreviewslink} googleReviewsLink={hauler.googlereviewslink} />}
				{state === STATES.STAGE3 && <FeedbackScreen />}
			</div>
			<p className="footer">POWERED BY TRASH JOES</p>
		</div>
	);
};

export default ReviewsPage;
