import Axios from 'axios';
import M from 'materialize-css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import Loading from '../../../components/Loader';
import companyService from '../../../services/companies';
import userService from '../../../services/users';

import { api } from '../../../config';
import CustomDisabledDays from './customDisabledDays';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const Select = ({ children, ...props }) => {
	return <select {...props}>{children}</select>;
};

const FileInput = (props) => {
	const { label, value, onChange, type, register, name } = props;

	const input = useRef(null);
	const [updated, setUpdated] = useState(false);
	const [exists, setExists] = useState(false);

	useEffect(() => {
		if (value) {
			setExists(false);
			fetch(`https://www.trashjoes.com${value}`)
				.then((d) => {
					d.status === 200 && setExists(true);
				})
				.catch((e) => console.log({ e }));
		}
	}, [value]);

	return (
		<>
			<label onClick={() => input.current.click()}>{label}</label>
			<i onClick={() => input.current.click()} style={{ cursor: 'pointer' }} className="left material-icons prefix">
				cloud_upload
			</i>
			{value && (
				<a href={`https://www.trashjoes.com${value}`} target="_blank">
					<i style={{ cursor: 'pointer' }} className="left material-icons prefix">
						cloud_download
					</i>
				</a>
			)}
			{exists && <small>EXISTS LOCALLY</small>}
			{!exists && <small>DOES NOT EXISTS LOCALLY</small>}
			<div>
				{updated && <span>Please remember to save</span>}
				{value && value.length > 0 && type === 'image' && <img src={value} alt="" style={{ height: '50px' }} />}
				{type === 'pdf' && <input ref={register} value={value} type="text" />}
			</div>

			<input name={name} ref={input} type="file" onChange={onChange} style={{ position: 'fixed', left: '9000px' }} />
		</>
	);
};

const Edit = (props) => {
	const { u, auth } = props.props;

	const { register, handleSubmit, reset, errors, setValue, getValues } = useForm({
		mode: 'onChange',
		defaultValues: useMemo(() => props.data, [props.data]),
	});
	const [form, setForm] = useState(props.data);

	const [company, setCompany] = useState({});
	const [uploadProgress, setUploadProgress] = useState(0);
	// const [companyId, setCompanyId] = useState(0);

	const [fetching, setFetching] = useState(false);
	// const hiddenFileInput = useRef(null);
	// const hiddenTOSResidentialInput = useRef(null);
	// const hiddenTOSCommercialInput = useRef(null);
	// const hiddenTOSrolloffInput = useRef(null);
	// const hiddenRollOffDetailsInput = useRef(null);
	const iframe = useRef(null);
	const [displayPicker1, setDisplayPicker1] = useState(false);
	const [displayPicker2, setDisplayPicker2] = useState(false);
	const [displayPicker3, setDisplayPicker3] = useState(false);
	const [displayPicker4, setDisplayPicker4] = useState(false);
	const [owners, setOwners] = useState(props.owners);
	const history = useHistory();
	// const [displayPicker3, setDisplayPicker3] = useState(false);

	useEffect(() => {
		if (owners.length > 0) setValue('ownerid', props.data.ownerid);
	}, [props.data, owners]);

	useEffect(() => setOwners(props.owners), [props.owners]);

	useEffect(() => {
		if (!fetching) {
			M.AutoInit();
			M.updateTextFields();
		}
	}, [fetching]);

	// useEffect(() => {
	// 	if (iframe.current) {
	// 		iframe.current.contentWindow.location.reload(true);
	// 	}
	// }, [form.theme.primaryColor, form.theme.secondaryColor, form.logo]);

	if (fetching) {
		return <Loading />;
	}

	const onSubmit = (values) => {
		values.id = Number(u);
		values.theme = form.theme;
		values.logo = form.logo;
		values.tosresidential = form.tosresidential;
		values.toscommercial = form.toscommercial;
		values.tosrolloff = form.tosrolloff;
		values.rolloffdetails = form.rolloffdetails;
		// values.daysnotice = form.daysnotice;

		new companyService()
			.update(values, auth.user.token)
			.then((json) => {
				if (json) {
					setForm({ ...form, tosResidentialUploaded: false, tosCommercialUploaded: false, tosrolloffUploaded: false });
					iframe.current.contentWindow.location.reload(true);

					M.toast({ html: 'Company Saved!', classes: 'green white-text' });
					history.push('/admin/company');
				}
			})
			.catch((e) => console.log({ e }));
		// .finally((f) => history.push('/admin/company'));
	};

	const slugify = (string) => {
		const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
		const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
		const p = new RegExp(a.split('').join('|'), 'g');

		return string
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, ''); // Trim - from end of text
	};

	const onImageFileUpload = (e) => {
		const file = e.target.files[0];

		if (file) {
			const fileTypes = ['jpg', 'jpeg', 'gif', 'png'];
			const ext = file.name.split('.').pop().toLowerCase();
			const isAccepted = fileTypes.indexOf(ext) > -1;

			if (isAccepted) {
				const fd = new FormData();
				const postUrl = `${api}image.cfc?method=upload`;

				fd.append('image', file, file.name);

				Axios.post(postUrl, fd, {
					headers: { 'Content-Type': 'application/json' },
					onUploadProgress: (e) => {
						setUploadProgress(Math.round((e.loaded / e.total) * 100));
					},
				})
					.then((e) => {
						setForm({ ...form, logo: e.data.file, logoUploaded: true });
					})
					.finally((e) => {});
			}
		}
	};

	const onTOSFileUpload = (e, filename) => {
		const file = e.target.files[0];

		if (file) {
			const fileTypes = ['pdf'];
			const ext = file.name.split('.').pop().toLowerCase();
			const isAccepted = fileTypes.indexOf(ext) > -1;

			if (isAccepted) {
				const fd = new FormData();
				const postUrl = `${api}pdf.cfc?method=upload`;

				fd.append('pdf', file, file.name);

				Axios.post(postUrl, fd, {
					headers: { 'Content-Type': 'application/json' },
					// onUploadProgress: (e) => {
					// 	setUploadProgress(Math.round((e.loaded / e.total) * 100));
					// },
				})
					.then((e) => {
						if (e.data.file && e.data.file.length > 2) {
							switch (filename) {
								case 'residential':
									setForm({ ...form, tosresidential: e.data.file, tosResidentialUploaded: true });
									break;
								case 'commercial':
									setForm({ ...form, toscommercial: e.data.file, tosCommercialUploaded: true });
									break;
								case 'rolloff':
									setForm({ ...form, tosrolloff: e.data.file, tosrolloffUploaded: true });
									break;
								case 'rolloffdetails':
									setForm({ ...form, rolloffdetails: e.data.file, rollOffDetailsUploaded: true });
									break;

								default:
									break;
							}
						} else {
							M.toast({ html: 'An error occured uploading your file, please try again!', classes: 'red white-text' });
						}
					})
					.catch((e) => console.log({ e }))
					.finally((e) => {});
			}
		}

		return false;
	};

	const deleteCompany = () => {
		const Authorization = ` Bearer ${btoa(auth.user.token)}`;
		Axios({
			url: `${URL}companies.cfc?method=deleteCompany&id=${Number(u)}`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: Authorization,
			},
		})
			.then((d) => d.status === 200 && d.data)
			.then((d) => d.success && M.toast({ html: 'Company was deleted' }))
			.catch((e) => M.toast({ html: 'Error delete the Company' }))
			.finally((e) => history.push('/admin/company'));
	};

	const cover = {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	};

	const popover = {
		position: 'absolute',
		zIndex: '2',
	};

	const colorPicker = {
		cursor: 'pointer',
		backgroundColor: 'white',
		height: '50px',
		float: 'left',
		width: '50px',
		lineHeight: '50px',
		textAlign: 'center',
	};

	return (
		<React.Fragment>
			{/* <input style={{ display: 'none' }} type="file" onChange={(e) => handleImageUpload(e)} ref={(fi) => setFileInput(fi)} /> */}

			<div className="row" style={{ paddingTop: '20px' }}>
				<div className="col s12" id="account">
					<form id="customerForm" onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col s12 m4">
								<div className="row">
									<div className="col s12 input-field">
										<input defaultValue={form.name} onChange={(el) => setValue('slug', slugify(el.target.value))} id="name" name="name" type="text" ref={register({ required: 'Full Name is required!' })} />
										<label htmlFor="name">Company Name</label>
										<span className="red-text helper-text">{errors.name && errors.name.message}</span>
									</div>
								</div>
							</div>
							<div className="col s12 m4">
								<div className="row">
									<div className="col s12 input-field">
										<input defaultValue={form.slug} id="slug" name="slug" type="text" ref={register({ required: 'Slug is required!' })} />
										<label className="active" htmlFor="slug">
											Slug
										</label>
										<span className="red-text helper-text">{errors.slug && errors.slug.message}</span>
									</div>
								</div>
							</div>
							<div className="col s12 m4">
								<div className="row">
									<div className="col s12 active input-field">
										<label className="active" htmlFor="ownerid">
											Owner
										</label>
										<select className="browser-default" id="ownerid" name="ownerid" ref={register} defaultValue={props.data.ownerid} disabled={auth.user.user.role !== 'admin'}>
											{/* <select name="ownerid" className="browser-default" id="ownerid" ref={register} disabled={auth.user.user.role !== 'admin'}> */}
											<option disabled>Select Owner</option>
											{owners.map((user) => (
												<option key={user.id} value={Number(user.id)}>
													{user.username}
												</option>
											))}
										</select>
										<span className="red-text helper-text">{errors.ownerid && errors.ownerid.message}</span>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col s12 m9">
								<div className="row">
									<div className="col s12 m4">
										<div className="row">
											<div className="col s12 input-field">
												<input defaultValue={form.mid} id="mid" name="mid" type="text" ref={register} />
												<label className="active" htmlFor="mid">
													Merchant ID
												</label>
												<span className="red-text helper-text">{errors.mid && errors.mid.message}</span>
											</div>
										</div>
									</div>

									<div className="col s12 m4">
										<div className="row">
											<div className="col s12 input-field">
												<input defaultValue={form.midauth} id="midusername" name="midusername" type="text" ref={register} />
												<label className="active" htmlFor="midusername">
													Merchant Username
												</label>
												<span className="red-text helper-text">{errors.midusername && errors.midusername.message}</span>
											</div>
										</div>
									</div>

									<div className="col s12 m4">
										<div className="row">
											<div className="col s12 input-field">
												<input id="midpassword" name="midpassword" type="password" ref={register} />
												<label className="active" htmlFor="midpassword">
													Merchant Password
												</label>
												<span className="red-text helper-text">{errors.midpassword && errors.midpassword.message}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col s12 m3">
								<div className="row">
									<div className="col s12 input-field">
										<select style={{ height: '90%' }} multiple size="3" className="browser-default" id="servicesAllowed" name="servicesAllowed" ref={register} disabled={auth.user.user.role !== 'admin'}>
											<option selected={form.servicesallowed?.includes('Residential')}>Residential</option>
											<option selected={form.servicesallowed?.includes('Commercial')}>Commercial</option>
											<option selected={form.servicesallowed?.includes('Roll-Offs')}>Roll-Offs</option>
										</select>
										<label className="active" htmlFor="servicesAllowed">
											Services Allowed
										</label>
										<span className="red-text helper-text">{errors.servicesAllowed && errors.servicesAllowed.message}</span>
									</div>
								</div>
							</div>

							<div className="col s12 m9">
								<div className="row">
									<div className="col s12 m4">
										<div className="row">
											<div className="col s12 input-field">
												<input defaultValue={form.phone} id="phone" name="phone" type="text" ref={register} />
												<label className="active" htmlFor="phone">
													Phone Number
												</label>
												<span className="red-text helper-text">{errors.phone && errors.phone.message}</span>
											</div>
										</div>
									</div>
									<div className="col s12 m4">
										<div className="row">
											<div className="col s12 active input-field">
												<label className="active" htmlFor="payfrequency">
													Offered Pay Frequency
												</label>
												<select multiple name="payfrequency" id="payfrequency" ref={register({ required: 'Pay Frequency is required!' })} defaultValue={form.payfrequency}>
													<option disabled>Select</option>
													<option value="1">Monthly</option>
													<option value="3">Quarterly</option>
													<option value="12">Yearly</option>
												</select>
												<span className="red-text helper-text">{errors.payfrequency && errors.payfrequency.message}</span>
											</div>
										</div>
									</div>
									<div className="col s12 m4">
										<div className="row">
											<div className="col s12 active input-field">
												<label className="active" htmlFor="offweekends">
													Work weekends?
												</label>
												<select name="offweekends" id="offweekends" ref={register} defaultValue={form.offweekends}>
													<option value="1">Yes</option>
													<option value="0">No</option>
												</select>
												<span className="red-text helper-text">{errors.offweekends && errors.offweekends.message}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col s12 m3">
								<div className="row">
									<div className="col s12 input-field">
										<select className="browser-default" id="daysnotice" name="daysnotice" ref={register}>
											{[0, 1, 2, 3, 4, 5, 6].map((num) => (
												<option selected={Number(form.daysnotice) === Number(num)}>{num}</option>
											))}
										</select>
										<label className="active" htmlFor="daysnotice">
											Days Notice
										</label>
										<span className="red-text helper-text">{errors.daysnotice && errors.daysnotice.message}</span>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col s12 m4">
								<FileInput label="Company Logo" onChange={onImageFileUpload} value={form.logo} type="image" name="logo" register={register} />
								{/* <label onClick={() => hiddenFileInput.current.click()}>Company Logo</label>
								<i onClick={() => hiddenFileInput.current.click()} style={{ cursor: 'pointer' }} className="left material-icons prefix">
									cloud_upload
								</i>
								{form.logo && form.logo.length > 0 && (
									<div>
										{form.logoUploaded && <span>Please remember to save</span>}
										<img src={form.logo} alt="" style={{ height: '50px' }} />
									</div>
								)}

								<input ref={hiddenFileInput} type="file" onChange={onImageFileUpload} style={{ position: 'fixed', left: '9000px' }} /> */}
							</div>
							<div className="col s12 m4">
								<FileInput label="Residential TOS (PDF)" onChange={(e) => onTOSFileUpload(e, 'residential')} value={form.tosresidential} type="pdf" name="tosresidential" register={register} />

								{/* <label onClick={() => hiddenTOSResidentialInput.current.click()}>Residential TOS (PDF)</label>
								<i onClick={() => hiddenTOSResidentialInput.current.click()} style={{ cursor: 'pointer' }} className="left material-icons prefix">
									cloud_upload
								</i>
								{form.tosresidential.length > 0 && <div>{form.tosResidentialUploaded && <span>Please remember to save</span>}</div>}
								<input defaultValue={form.tosresidential} id="tosresidential" name="tosresidential" type="text" ref={register} />

								<input ref={hiddenTOSResidentialInput} type="file" onChange={(e) => onTOSFileUpload(e, 'residential')} style={{ position: 'fixed', left: '9000px' }} /> */}
							</div>
							<div className="col s12 m4">
								<FileInput label="Commercial TOS (PDF)" onChange={(e) => onTOSFileUpload(e, 'commercial')} value={form.toscommercial} type="pdf" name="toscommercial" register={register} />
								{/* <label onClick={() => hiddenTOSCommercialInput.current.click()}>Commercial TOS (PDF)</label>
								<i onClick={() => hiddenTOSCommercialInput.current.click()} style={{ cursor: 'pointer' }} className="left material-icons prefix">
									cloud_upload
								</i>
								{form.toscommercial.length > 0 && <div>{form.tosCommercialUploaded && <span>Please remember to save</span>}</div>}
								<input defaultValue={form.toscommercial} id="toscommercial" name="toscommercial" type="text" ref={register} />

								<input ref={hiddenTOSCommercialInput} type="file" onChange={(e) => onTOSFileUpload(e, 'commercial')} style={{ position: 'fixed', left: '9000px' }} /> */}
							</div>
							<div className="col s12 m4">
								<FileInput label="Roll-Offs TOS (PDF)" onChange={(e) => onTOSFileUpload(e, 'rolloff')} value={form.tosrolloff} type="pdf" name="tosrolloff" register={register} />
								{/* <label onClick={() => hiddenTOSrolloffInput.current.click()}>Roll-Offs TOS (PDF)</label>
								<i onClick={() => hiddenTOSrolloffInput.current.click()} style={{ cursor: 'pointer' }} className="left material-icons prefix">
									cloud_upload
								</i>
								{form.tosrolloff.length > 0 && <div>{form.tosrolloffUploaded && <span>Please remember to save</span>}</div>}
								<input defaultValue={form.tosrolloff} id="tosrolloff" name="tosrolloff" type="text" ref={register} />

								<input ref={hiddenTOSrolloffInput} type="file" onChange={(e) => onTOSFileUpload(e, 'rolloff')} style={{ position: 'fixed', left: '9000px' }} /> */}
							</div>
							<div className="col s12 m4">
								<FileInput label="Roll-Offs Details (PDF)" onChange={(e) => onTOSFileUpload(e, 'rolloffdetails')} value={form.rollOffDetailsUploaded} type="pdf" name="rollOffDetailsUploaded" register={register} />
								{/* <label onClick={() => hiddenRollOffDetailsInput.current.click()}>Roll-Offs Details (PDF)</label>
								<i onClick={() => hiddenRollOffDetailsInput.current.click()} style={{ cursor: 'pointer' }} className="left material-icons prefix">
									cloud_upload
								</i>
								{form.rolloffdetails.length > 0 && <div>{form.rollOffDetailsUploaded && <span>Please remember to save</span>}</div>}
								<input defaultValue={form.rolloffdetails} id="rolloffdetails" name="rolloffdetails" type="text" ref={register} />

								<input ref={hiddenRollOffDetailsInput} type="file" onChange={(e) => onTOSFileUpload(e, 'rolloffdetails')} style={{ position: 'fixed', left: '9000px' }} /> */}
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col s12 m6">
								<input defaultValue={form?.facebookreviewslink} id="facebookreviewslink" name="facebookreviewslink" type="text" ref={register} />
								<label className="active" htmlFor="facebookreviewslink">
									Facebook Review Link
								</label>
								<span className="red-text helper-text">{errors.facebookreviewslink && errors.facebookreviewslink.message}</span>
							</div>
							<div className="col s12 m6">
								<input defaultValue={form?.googlereviewslink} id="googlereviewslink" name="googlereviewslink" type="text" ref={register} />
								<label className="active" htmlFor="googlereviewslink">
									Google Reviews Link
								</label>
								<span className="red-text helper-text">{errors.googlereviewslink && errors.googlereviewslink.message}</span>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col s12 m3">
								<div className="row">
									<div className="col s12 input-field active">
										<label className="active" htmlFor="color">
											Primary Color
										</label>
										<div className="col s12" style={{ height: '50px', cursor: 'pointer', backgroundColor: form.theme.primaryColor }} onClick={() => setDisplayPicker1(true)}>
											<i style={{ ...colorPicker, backgroundColor: 'white', color: form.theme.primaryColor }} className="material-icons">
												format_color_fill
											</i>
										</div>
										{displayPicker1 && (
											<div style={popover}>
												<div style={cover} onClick={() => setDisplayPicker1(false)} />
												<SketchPicker
													color={form.theme.primaryColor}
													onChangeComplete={(c) => {
														setValue('color', c.hex);
														setForm({ ...form, theme: { ...form.theme, primaryColor: c.hex } });
													}}
												/>
											</div>
										)}
										<span className="red-text helper-text">{errors.name && errors.name.message}</span>
									</div>
								</div>
							</div>
							<div className="col s12 m3">
								<div className="row">
									<div className="col s12 input-field active">
										<label className="active" htmlFor="color">
											Primary Text Color
										</label>
										<div className="col s12" style={{ height: '50px', cursor: 'pointer', backgroundColor: form.theme.primaryTextColor }} onClick={() => setDisplayPicker2(true)}>
											<i style={{ ...colorPicker, backgroundColor: 'white', color: form.theme.primaryTextColor }} className="material-icons">
												format_color_fill
											</i>
										</div>
										{displayPicker2 && (
											<div style={popover}>
												<div style={cover} onClick={() => setDisplayPicker2(false)} />
												<SketchPicker
													color={form.theme.primaryTextColor}
													onChangeComplete={(c) => {
														setValue('color', c.hex);
														setForm({ ...form, theme: { ...form.theme, primaryTextColor: c.hex } });
													}}
												/>
											</div>
										)}
										<span className="red-text helper-text">{errors.name && errors.name.message}</span>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col s12 m3">
								<div className="row">
									<div className="col s12 input-field active">
										<label className="active" htmlFor="color">
											Secondary Color
										</label>
										<div className="col s12" style={{ height: '50px', cursor: 'pointer', backgroundColor: form.theme.secondaryColor }} onClick={() => setDisplayPicker3(true)}>
											<i style={{ ...colorPicker, backgroundColor: 'white', color: form.theme.secondaryColor }} className="material-icons">
												format_color_fill
											</i>
										</div>
										{displayPicker3 && (
											<div style={popover}>
												<div style={cover} onClick={() => setDisplayPicker3(false)} />
												<SketchPicker
													color={form.theme.secondaryColor}
													onChangeComplete={(c) => {
														setValue('color', c.hex);
														setForm({ ...form, theme: { ...form.theme, secondaryColor: c.hex } });
													}}
												/>
											</div>
										)}
										<span className="red-text helper-text">{errors.name && errors.name.message}</span>
									</div>
								</div>
							</div>
							<div className="col s12 m3">
								<div className="row">
									<div className="col s12 input-field active">
										<label className="active" htmlFor="color">
											Secondary Text Color
										</label>
										<div className="col s12" style={{ height: '50px', cursor: 'pointer', backgroundColor: form.theme.secondaryTextColor }} onClick={() => setDisplayPicker4(true)}>
											<i style={{ ...colorPicker, backgroundColor: 'white', color: form.theme.secondaryTextColor }} className="material-icons">
												format_color_fill
											</i>
										</div>
										{displayPicker4 && (
											<div style={popover}>
												<div style={cover} onClick={() => setDisplayPicker4(false)} />
												<SketchPicker
													color={form.theme.secondaryTextColor}
													onChangeComplete={(c) => {
														setValue('color', c.hex);
														setForm({ ...form, theme: { ...form.theme, secondaryTextColor: c.hex } });
													}}
												/>
											</div>
										)}
										<span className="red-text helper-text">{errors.name && errors.name.message}</span>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col s12 display-flex justify-content-end mt-3">
								<Link to="/admin/company" style={{ marginRight: 'auto' }} className="btn btn-light">
									Cancel
								</Link>
								<button type="submit" className="btn indigo">
									Save changes
								</button>
								<button type="button" onClick={() => deleteCompany(form.id)} className="btn red white-text">
									Delete
								</button>
							</div>
						</div>
						{/* {form.logo !== company.logo ? 'Save Uploaded' : 'is saved'}
											{form.logo.length > 0 && <img alt="" src={form.logo} style={{ height: '200px' }} />} */}
					</form>
					<div style={{ marginTop: '20px' }} className="row">
						<iframe ref={iframe} src={`/h/${props.data.slug}`} style={{ width: '100%', height: '800px' }} />
					</div>
				</div>
			</div>
			<div className="content-overlay"></div>
		</React.Fragment>
	);
};

const TabSetup = (props) => {
	const [tab, setTab] = useState('details');

	return (
		<>
			<div className="col s12" style={{ marginBottom: '20px' }}>
				<ul className="tabs">
					<li className="tab col">
						<a style={{ color: 'black' }} className={`${tab === 'details' && 'active'}`} href="#tabContentDetails" onClick={() => setTab('details')}>
							Details
						</a>
					</li>
					<li className="tab col">
						<a style={{ color: 'black' }} className={`${tab === 'disabled' && 'active'}`} href="#tabContentDisabled" onClick={() => setTab('disabled')}>
							Disabled Calendar Days
						</a>
					</li>
				</ul>
			</div>
			{tab === 'details' && (
				<div id="tabContentDetails">
					<Edit {...props} />
				</div>
			)}
			{tab === 'disabled' && (
				<div id="tabContentDisabled">
					<CustomDisabledDays disabledDates={props.data.disabledDates} {...props} />
				</div>
			)}
		</>
	);
};

const useFetch = ({ u, auth }) => {
	const [status, setStatus] = useState('idle');
	const [data, setData] = useState(null);
	const [owners, setOwners] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			setStatus('fetching');

			const a = () =>
				new companyService().getCompany(u, auth.user.token).then((json) => {
					if (json) {
						try {
							json.theme = JSON.parse(json.theme) || {};
						} catch (e) {
							json.theme = {};
						}

						try {
							json.payfrequency = JSON.parse(json.payfrequency) || [];
						} catch (e) {
							json.payfrequency = [];
						}

						setData(json);
						setStatus('fetched');
					}
				});

			const b = () =>
				new userService().getUsers(auth.user.token).then((json) => {
					if (json) {
						setOwners(json);
					}
				});

			Promise.all([a(), b()])
				// .then((response) => response))
				.then((json) => {
					if (json) {
						setStatus('fetched');
					}
				});
		};

		fetchData();
	}, [u, auth.user.token]);

	return { status, data, owners };
};

const Wrapper = (props) => {
	const { status, data, owners } = useFetch({ u: props.u, auth: props.auth });

	const allProps = { props, status, data, owners };

	return (
		<>
			<div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Company edit</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<Link to="/admin">Home</Link>
								</li>
								<li className="breadcrumb-item">
									<Link to="/admin/company">Company</Link>
								</li>
								<li className="breadcrumb-item active">Company Edit</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<div className="container">
					<div className="section users-edit">
						<div className="card">
							<div className="card-content" style={{ marginTop: 0 }}>
								{status === 'fetched' && <TabSetup {...allProps} />}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Wrapper;
